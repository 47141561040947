import React, { Component } from "react"
import PropTypes from "prop-types"
import { Map, TileLayer, Marker } from "react-leaflet"
import L from 'leaflet';

class BikeMap extends Component {
  state = {
    loading: false,
    error: false,
    region: {
      lat: 52.51,
      lng: 13.42,
      cityName: "Berlin Neukölln",
      zoom: 11,
    }
  }

  onMapChange(e) {
    console.log("onmapchange, e: ", e)
  }

  componentDidMount() {
    if (this.leafletmap) {
      const leafletMap = this.leafletmap.leafletElement
      leafletMap.on("moveend", e => this.onMapChange(e))
    }
  }

  render() {
      console.log('state: ', this.state)
    return (
      <div className="bikemap-components-wrapper">
      <h2>Bikemap Component</h2>
      {typeof window !== 'undefined' && (
        <Map
          style={{ height: "100%", minHeight: "400px", width: "100%" }}
          ref={m => (this.leafletMap = m)}
          center={[this.state.region.lat, this.state.region.lng]}
        //   zoom={this.state.zoom}
          maxZoom={20}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.55}
          className="map-container flex-grow-1"
        >
          <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        </Map>
      )

      }
      </div>
    )
  }
}

BikeMap.propTypes = {}

export default BikeMap
