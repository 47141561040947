import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import BikeMap from "../components/bikemap"
import GeneralMap from "../components/generalMap"

const IndexPage = ({ data }) => {
  console.log(data)

  return (
    <Layout>
      <SEO title="Home" />
      <small>
        This projectpage in progress <Link to="/about">About the project</Link>
      </small>
      <GeneralMap providerImages={data.images} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    images: allFile(filter: { relativeDirectory: { eq: "provider" } }) {
      totalCount
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 45){
          ...GatsbyImageSharpFluid
        }
        }
      }
    }
    imageTwo: file(relativePath: { eq: "../provider/bcycle.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
